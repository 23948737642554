import React, { useContext, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { Trans, useTranslation } from "react-i18next"

import Layout from "../components/layouts"
import OrderNumberForm from "../components/self-return/order-number-form"
import ReturnOrder from "../components/self-return/return-order"
import SEO from "../components/seo"
import NotificationContext from "../context/NotificationContext"
import { useTranslationContext } from "../context/TranslationContext"
import Medusa from "../services/api"

const StepContainer = styled.div`
  .step {
    .text {
      font-size: 18px;
      font-weight: 300;
    }

    .step-header {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .logistics-container {
      margin: 20px 0;
      .logistics {
        margin: 0;
        padding: 0;
      }
    }
  }
  margin: 70px 16px;
  .error {
    color: ${(props) => props.theme.colors.danger};
  }

  a {
    color: ${(props) => props.theme.colors.black};
    text-decoration: none;
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    margin: 100px auto;
    max-width: 1000px;

    .step {
      .text {
        font-size: 24px;
      }
    }
  }

  .form {
    max-width: 400px;
    margin: 40px auto;

    .form-input-field {
      padding-bottom: 20px;
    }
  }
`

const canonicals = {
  ["en-US"]: "/returns",
  ["de-DE"]: "/de/retouren",
}

const SelfReturn = () => {
  const [step, setStep] = useState(1)
  const [order, setOrder] = useState()
  const { pushNotification } = useContext(NotificationContext)
  const { t } = useTranslation()

  const { setTranslationContext } = useTranslationContext()

  useEffect(() => {
    setTranslationContext({
      locale: "en-US",
      canonicals: canonicals,
    })
  }, [setTranslationContext])

  const handleOrderNumberSubmit = async (values) => {
    const { orderNumber, email, zipCode } = values

    Medusa.orders
      .lookup({
        display_id: orderNumber.trim(),
        email: email.trim(),
        "shipping_address[postal_code]": zipCode.trim(),
      })
      .then(async ({ data }) => {
        const order = data.order

        if (order.status === "canceled") {
          pushNotification({
            id: "not_shipped",
            body: t("order_canceled"),
            dismiss: {
              duration: 3000,
            },
          })
          return
        }
        if (
          !(
            order.fulfillment_status === "shipped" ||
            order.fulfillment_status === "partially_returned"
          )
        ) {
          pushNotification({
            id: "not_shipped",
            body: t("order_not_shipped"),
            dismiss: {
              duration: 3000,
            },
          })
          return
        }

        order.return_shipping = null
        order.items = order.items.reduce((acc, next) => {
          // if this item is fully returned, don't include it
          if (next.returned_quantity === next.quantity) {
            return acc
          }

          // if this item is partially returned, deduct it from the quantity available
          if (next.returned_quantity) {
            next.quantity -= next.returned_quantity
          }

          next.checked = false
          next.reason = null
          next.max_quantity = next.quantity

          // change unit price to be the refundable price
          next.unit_price = next.refundable / next.quantity

          acc.push(next)
          return acc
        }, [])

        // Initial state for return_shipping
        const selfReturnOption = {
          name: "self_return",
          id: "self-return-option",
          amount: 0,
        }

        const returnReasons = await Medusa.returnReasons
          .list()
          .then(({ data }) => data.return_reasons)
          .catch((err) => console.error(err))

        order.return_reasons = returnReasons.reduce((acc, next) => {
          const result = next
          result.display = next.label

          acc.push(result)
          return acc
        }, [])

        const shippingOptions = await Medusa.shippingOptions
          .list({
            is_return: true,
            region_id: order.region_id,
          })
          .then(({ data }) => data.shipping_options)
          .catch((err) => console.error(err))

        const options = shippingOptions
        // Append the free option
        options.push(selfReturnOption)
        order.return_shipping_options = options
        order.return_shipping = {
          option_id: selfReturnOption.id,
          amount: selfReturnOption.amount,
        }

        setOrder(order)
        setStep(2)
      })
      .catch((err) => console.error(err))
  }

  const handleSwapSubmit = () => {
    try {
      const items = order.items.reduce(
        (acc, next) => {
          if (next.checked) {
            if (!next.reason?.value) {
              throw new Error("no-reason")
            } else {
              acc.return_items.push({
                item_id: next.id,
                quantity: next.quantity,
                reason_id: next.reason.id,
              })

              if (next.swap_to) {
                acc.additional_items.push({
                  variant_id: next.swap_to.id,
                  quantity: next.quantity,
                })
              }
            }
          }

          return acc
        },
        { return_items: [], additional_items: [] }
      )

      if (items.length === 0) {
        throw new Error("no-items")
      }

      let swapOrder = {
        order_id: order.id,
        additional_items: items.additional_items,
        return_items: items.return_items,
      }

      // If the return is self return, dont append return_shipping to the API submit
      if (order.return_shipping.option_id !== "self-return-option") {
        swapOrder.return_shipping_option = order.return_shipping.option_id
      }

      let returnOrder
      if (swapOrder.additional_items.length === 0) {
        returnOrder = {
          order_id: swapOrder.order_id,
          items: swapOrder.return_items,
        }
        if (order.return_shipping.option_id !== "self-return-option") {
          returnOrder.return_shipping = {
            option_id: order.return_shipping.option_id,
          }
        }
      }

      const isReturn = Boolean(returnOrder.order_id)
      const payload = isReturn ? returnOrder : swapOrder

      Medusa[isReturn ? "returns" : "swaps"]
        .create(payload)
        .then(() => {
          pushNotification({
            id: "return-success",
            body: t("return_successful"),
            dismiss: {
              duration: 3000,
            },
          })
          setStep(3)
        })
        .catch((err) => {
          console.error(err)

          pushNotification({
            id: "cant-swap",
            body: t("cant_create_swap"),
            dismiss: {
              duration: 3000,
            },
          })
        })
    } catch (err) {
      if (err.message === "no-reason") {
        pushNotification({
          id: "no-reason",
          body: t("choose_reason_notification"),
          dismiss: {
            duration: 3000,
          },
        })
      } else if (err.message === "no-items") {
        pushNotification({
          id: "no-items",
          body: t("choose_items_notification"),
          dismiss: {
            duration: 3000,
          },
        })
      } else {
        pushNotification({
          id: "cant-swap",
          body: t("cant_create_swap"),
          dismiss: {
            duration: 3000,
          },
        })
      }
    }
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="step">
            <h2 className="text">
              {t("manage_your_return")} - {t("step_amount", { amount: 1 })}
            </h2>
            <p className="text">
              <Trans i18nKey="manage_your_return_description">
                Please enter the relevant order details below to progress to the
                next step in the return process. Note that this page is for full
                or partial returns. If you wish to exchange items, please
                contact our customer service via email at
                <a href="mailto: customercare@teklafabrics.com">
                  customercare@teklafabrics.com
                </a>
                .
              </Trans>
            </p>

            <div className="form">
              <OrderNumberForm
                onSubmit={(values) => handleOrderNumberSubmit(values)}
              />
            </div>
          </div>
        )
      case 2:
        return (
          <div className="step">
            <h2 className="text">
              {t("manage_your_return")} - {t("step_amount", { amount: 2 })}
            </h2>
            <p className="text">
              <Trans i18nKey="select_items_for_return">
                Select the items you wish to return, as well as the reasons you
                would like to return them. This information is incredibly
                valuable to us and we appreciate your feedback. If you
                experience any trouble during this step, please reach out to
                <a href="mailto: customercare@teklafabrics.com">
                  customercare@teklafabrics.com
                </a>
                .
              </Trans>
            </p>

            <ReturnOrder
              order={order}
              setOrder={setOrder}
              handleSwapSubmit={handleSwapSubmit}
            />
          </div>
        )
      case 3:
        return (
          <div className="step">
            <h2 className="text">{t("return_successful")}</h2>
            {order.return_shipping?.option_id === "self-return-option" ? (
              <div>
                <div>
                  <p className="step-header">
                    {t("step_amount", { amount: 1 })}
                  </p>
                  <p>{t("return_self_step_1")}</p>
                </div>
                <div>
                  <p className="step-header">
                    {t("step_amount", { amount: 2 })}
                  </p>
                  <p>{t("return_self_step_2")}</p>
                  <div className="logistics-container">
                    <p className="logistics">Att: Tekla Fabric</p>
                    <p className="logistics">Blomstervej 68</p>
                    <p className="logistics">8381 Tilst</p>
                    <p className="logistics">Denmark</p>
                  </div>
                </div>
                <div>
                  <p className="step-header">
                    {t("step_amount", { amount: 3 })}
                  </p>
                  <p>{t("return_await_email_confirmation")}</p>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <p className="step-header">
                    {t("step_amount", { amount: 1 })}
                  </p>
                  <p>{t("return_label_step_1")}</p>
                </div>
                <div>
                  <p className="step-header">
                    {t("step_amount", { amount: 2 })}
                  </p>
                  <p>{t("return_label_step_2")}</p>
                </div>
                <div>
                  <p className="step-header">
                    {t("step_amount", { amount: 3 })}
                  </p>
                  <p>{t("return_label_step_3")}</p>
                </div>
                <div>
                  <p className="step-header">
                    {t("step_amount", { amount: 4 })}
                  </p>
                  <p>{t("return_await_email_confirmation")}</p>
                </div>
              </div>
            )}
          </div>
        )
    }
  }
  return (
    <Layout>
      <SEO title={t("return_seo_title")} />
      <StepContainer>{renderStep()}</StepContainer>
    </Layout>
  )
}

export default SelfReturn
